import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import rackDetails from './rackDetails';
export default {
  name: 'assetLocators',
  components: {
    rackDetails
  },
  watch: {
    currentPage: function() {
      this.getAssetLocGridData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAssetLocGridData();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      locCode: null,
      locName: null,
      status: {
        value: null,
        text: null
      },
      location: {
        value: null,
        text: null
      },
      defaultValue: {
        text: null,
        value: null
      },
      showRackModal: false,
      rackRowData: null,
      assetLocatorsData: [],
      assetLocatorsFields: [
        {
          key: 'concatenated_segment',
          label: 'FA Location'
        },
        {
          key: 'locator_name',
          class:'d-none'
        },
        {
          key: 'locator_code',
          class:'d-none'
        },
        {
          key: 'status'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      showAlert: false,
      showValueSetModal: false,
      payload: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          //   this.showHideAddFaLocationModal(true);
        }
      }
    });
  },
  methods: {
    getAssetLocGridData() {
      const payload = {
        page: this.currentPage - 1,
        limit: this.perPage,
        locator_code: this.locCode,
        locator_name: this.locName,
        fa_location_id: this.location.value,
        status: this.status.value
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetLocGridData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assetLocatorsData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.rackRowData = item;
      this.showHideRackModal(true);
    },
    showHideRackModal(flag) {
      this.showRackModal = flag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_VSET) {
        this.status = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        this.location = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'STATUS_VSET':
          this.status = this.defaultValue;
          break;
        case 'ASSET_LOCATION':
          this.location = this.defaultValue;
          break;
      }
    },
    clear() {
      this.locCode = null;
      this.locName = null;
      this.status = {
        text: null,
        value: null
      };
      this.location = {
        text: null,
        value: null
      };
      this.totalRows = null;
      this.assetLocatorsData = [];
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
